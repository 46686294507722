import React, { useEffect, useState } from "react";
import axios from "axios";
import "./DashboardProducts.css";
import { SpinnerInfinity } from "spinners-react";

const DashboardProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        "https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products"
      );
      setProducts(res.data["data"]);
      console.log(res.data["data"]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="dashboard-products">
      {products.length > 0 ? (
        products.map((item, index) => (
          <div id="product-card" key={index}>
            <div className="card-top-bg">
              <div className="card-top-row">
                <h1>
                  Minimum Investment: ₹
                  {new Intl.NumberFormat("en-IN").format(
                    item.minimumInvestment
                  )}
                </h1>

                <p>
                  {item.soldPercentage}% <br />
                  sold
                </p>
              </div>
              <div className="card-top-row-2">
                <h1>{item.productName}</h1>
              </div>
            </div>
            <div className="card-bottom">
              <p>
                TENURE: &nbsp;<span>{item.tenure} Days</span>
              </p>
              <p>
                YIELD: &nbsp;
                <span>
                  {item.XIRR}% <span>/annum</span>
                </span>
              </p>
            </div>
          </div>
        ))
      ) : (
        <div className="infinity-spinner">
          <SpinnerInfinity
            size={90}
            thickness={180}
            speed={105}
            color="rgba(172, 57, 59, 1)"
            secondaryColor="rgba(0, 0, 0, 0.78)"
          />
          
        </div>
      )}
    </div>
  );
};

export default DashboardProducts;
