import Navbar from "../Navbar/Navbar";
import "./Home.css";
import pieChartImage from "./images/pie-chart.svg";
import pieChartData from "./images/pie-chart-data.svg";
import boxContent1 from "./images/alternative icon.png";
import boxContent2 from "./images/liqudity icon.png";
import boxContent3 from "./images/insuredicon.png";
import boxContent4 from "./images/Robust icon.png";
import boxContent5 from "./images/investments icon.png";
import boxContent6 from "./images/boxContent6.png";
import RightArrow from "./utils/RightArrow";
import income_icon from "./images/incomeicon.svg";
import VerticalLinearStepper from "../Miui/VerticalStepper";
import CardCarousel from "../Miui/CardCarousel";
import MyAccordion from "../Miui/Accordion/MyAccordion";
import Footer from "../Footer/Footer";
import strategies_products from "./images/strategies_products.svg";
import piechart from "./images/pie-chart-third.svg";
import investment_steps from "./images/investment-steps.svg";
import fractionedMoneyBg from "./images/fractioned-money-bg.svg";
import fractionedMoneyImage from "./images/fractioned-money-section-img.svg";
import incomeImg from "./images/income-img.svg";
import growthImg from "./images/growth-img.svg";
import wideRangeAlternatives from "./images/wide-range-alternatives.svg";
import tailoredliquidity from "./images/tailored-liquidity.svg";
import resilientRobust from "./images/resilient-robust.svg";
import insuredManaged from "./images/insured_managed.svg";
import growYourInvestment from "./images/growyourinvestment.svg";
import impactBeyondReturns from "./images/impact-beyond-returns.svg";
import incomeLeftIcon from "./images/income-left-icon.svg";
import incomeRightIcon from "./images/income-right-icon.svg";
import growthLeftIcon from "./images/growth_left_icon.svg";
import growthRightIcon from "./images/growth_right_icon.svg";

const Home = () => {
  return (
    <div id="home-container">
      <div id="home">
        <Navbar />
        <div id="heading-block">
          <p className="top-tagline">
            Limitless Opportunities <span>∞</span> Boundless Untapped Market
          </p>

          <p className="heading">
            Invest with the most powerful <br /> <span>SME</span> ecosystem in
            India
          </p>

          <p className="sub-heading">Earn up to 22%* Annual Interest</p>

          <div className="invest-now-btn">
            <button>Invest Now</button>
          </div>

          <p className="supporting-text">Investment starts at ₹5,00,000</p>
        </div>

        <div id="portfolio1">
          <div className="investment-portfolio1">
            <p>Our Investment portfolio</p>

            <div className="portfolio-table1">
              <div className="portfolio-col1">
                <p>
                  Active <br />
                  projects
                </p>
                <p className="num">4</p>
              </div>
              <div className="portfolio-col1">
                <p>
                  Closed <br /> projects
                </p>
                <p className="num">5</p>
              </div>
              <div className="portfolio-col1">
                <p>
                  Total <br /> AUM
                </p>
                <p className="num">₹6cr.</p>
              </div>
              <div
                className="portfolio-col1"
                style={{ backgroundColor: "transparent" }}
              >
                <div
                  style={{
                    backgroundColor: "transparent",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div style={{ backgroundColor: "transparent" }}>
                    <img
                      src={pieChartImage}
                      alt="pie-chart-image"
                      style={{ backgroundColor: "transparent" }}
                      className="pie-chart-image"
                    />
                  </div>

                  <div style={{ backgroundColor: "transparent" }}>
                    <img
                      src={pieChartData}
                      alt=""
                      style={{ backgroundColor: "transparent" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="investment-portfolio1">
            <p>Our customers believe in us</p>

            <div className="portfolio-table1">
              <div className="portfolio-col1">
                <p>
                  Total <br />
                  Userbase
                </p>
                <p className="num">5</p>
              </div>
              <div className="portfolio-col1">
                <p>
                  Capital & Interest <br />
                  paid back
                </p>
                <p className="num">₹0.6cr.</p>
              </div>
              <div className="portfolio-col1">
                <p>
                  Average <br /> ROI
                </p>
                <p className="num">19%</p>
              </div>
              <div className="portfolio-col1">
                <p>
                  Asset <br /> backing
                </p>
                <p className="num">90%</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="second-section">
        <div className="heading-block">
          <p className="second-section-heading">
            Tailored Investment <br />
            Options Designed for Your Success
          </p>
        </div>

        <div className="second-section-grid">
          <div className="second-section-card">
            <h1>Wide range of alternatives</h1>
            <p>
              We carefully select opportunities across diverse MSME sectors to
              provide our customers with steady and predictable returns,
              tailored to their specific investment goals.
            </p>

            <div className="second-section-card-img">
              <img src={wideRangeAlternatives} alt="poster" />
            </div>
          </div>

          <div className="second-section-card">
            <h1>Tailored liquidity for you</h1>
            <p>
              Our investment durations start at 2 months and extend to 60
              months, offering interest rates from 15% to 24% per
              annum-significantly higher than traditional fixed deposits,
              ensuring better returns.
            </p>

            <div className="second-section-card-img">
              <img src={tailoredliquidity} alt="poster" />
            </div>
          </div>

          <div className="second-section-card">
            <h1>Resilient & Robust</h1>
            <p>
              Each investment is backed by real-world assets like land,
              machinery, or inventory, all managed by seasoned experts, ensuring
              both the security and growth of your investment.
            </p>

            <div className="second-section-card-img">
              <img src={resilientRobust} alt="poster" />
            </div>
          </div>

          <div className="second-section-card">
            <h1>Insured & Managed</h1>
            <p>
              We focus on industries that perform well during economic shifts,
              such as B2B services, export-driven industries, and mass-market
              products, offering stable and reliable growth.
            </p>

            <div className="second-section-card-img">
              <img src={insuredManaged} alt="poster" />
            </div>
          </div>

          <div className="second-section-card">
            <h1>Grow your investments</h1>
            <p>
              You can schedule a visit to see the business firsthand, giving you
              a deeper understanding of your investment and a clear insight into
              its potential and add value from your experience and network.
            </p>

            <div className="second-section-card-img">
              <img src={growYourInvestment} alt="poster" />
            </div>
          </div>

          <div className="second-section-card">
            <h1>Impact beyond returns</h1>
            <p>
              Your investments do more than just yield returns-they contribute
              to building sustainable businesses and turn aspirations into
              reality. Join us in making a positive impact while achieving
              your financial goals.
            </p>

            <div className="second-section-card-img">
              <img src={impactBeyondReturns} alt="poster" />
            </div>
          </div>
        </div>
      </div>

      <div id="third-section">
        <div className="third-section-content">
          <p>
            Discover Our Investment <br />
            Strategies
          </p>

          <div className="income-img">
            <div className="income-head-content">
              <p>STEADY EARNINGS</p>
              <p>Income</p>
              <p>
                Prioritizes capital preservation and income generation by
                allocation predominantly to credit assets.
              </p>
            </div>
            <div style={{ marginTop: "10px" }} className="image-icon">
              <div>
                <img src={incomeLeftIcon} alt="icon" />
              </div>
              <div>
                <img src={incomeRightIcon} alt="icon" />
              </div>
            </div>
            {/* <img src={incomeImg} alt="income-descriptive-image" /> */}
          </div>

          <div className="income-img">
            <div className="income-head-content">
              <p>WEALTH GENERATION</p>
              <p>Growth</p>
              <p>
                Targets long-term capital appreciation through equity-focused
                investing with potential for dividend payouts.
              </p>
            </div>
            <div style={{ marginTop: "10px" }} className="image-icon">
              <div>
                <img src={growthLeftIcon} alt="icon" />
              </div>
              <div>
                <img src={growthRightIcon} alt="icon" />
              </div>
            </div>
            {/* <img src={incomeImg} alt="income-descriptive-image" /> */}
          </div>
        </div>
      </div>

      <div id="fourth-section">
        <div className="fourth-section-top">
          <h1 className="fourth-section-heading">
            Ready to Invest? <span>Here’s How!</span>
          </h1>
        </div>

        <div className="fourth-section-mid">
          <VerticalLinearStepper />
        </div>

        <div className="fourth-section-bottom">
          <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-center md:text-left">
            INVEST NOW
          </p>
        </div>
      </div>

      <div className="fractioned-money-section">
        <div className="content">
          <div className="text-content">
            <p>
              How does <br /> <span>fractioned.money</span> work?
            </p>

            <p>
              Fractioned offers everyone the chance to participate in debt
              investments with small and medium-sized enterprises (SMEs) backed
              by tangible assets. <br /> <br /> As a lender/investor, you
              collaborate with dozens, or even hundreds, of other individuals to
              finance the purchase orders or receivables of SMEs. This
              collective support generates a stable return on your investment,
              offering both security and growth potential. <br /> <br /> We
              serve as your trusted intermediary, efficiently managing
              the entire process.
            </p>

            <p>Our expertise is driven by:</p>
            <div className="expertise-col">
              <p>Experience and practical involvement in the business</p>
              <p>Substantial network established in the industry</p>
              <p>Deep understanding of the market trends</p>
            </div>
          </div>
        </div>

        <div className="img-content">
          <img src={fractionedMoneyImage} alt="fractioned-money-image" />
        </div>
      </div>

      <div className="fifth-section">
        <h1>
          Join our community to explore&nbsp;
          <br /> opportunities tailored just for you
        </h1>

        <CardCarousel />
      </div>

      {/* <div id="sixth-section">
        <h1>
          Have questions?<span> We’ve got you.</span>
          <p>
            We’ve answered the most common questions to guide you through our
            services and investment options with ease.
          </p>
        </h1>

        <div className="faq-grid">
          <MyAccordion />
        </div>

        <div className="sixth-section-last">
          <p>Have a question in mind?</p>
          <p>
            Contact us, support center 24/7 <span>Schedule a Call</span>
          </p>
        </div>
      </div> */}

      <MyAccordion />

      <Footer />
    </div>
  );
};

export default Home;
