import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import img1 from "../Home/images/growthicon.svg";
import "./CardCarousel.css";
import { color } from "framer-motion";

const CardCarousel = () => {
  const testimonials = [
    {
      imgSrc: img1,
      name: "Mukesh Ambani",
      testimonial:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem, quas. Provident ",
    },
    {
      imgSrc: img1,
      name: "Mukesh Ambani",
      testimonial:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem, quas. Provident ",
    },
    {
      imgSrc: img1,
      name: "Mukesh Ambani",
      testimonial:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem, quas. Provident ",
    },
    {
      imgSrc: img1,
      name: "Mukesh Ambani",
      testimonial:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem, quas. Provident ",
    },
    {
      imgSrc: img1,
      name: "Mukesh Ambani",
      testimonial:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem, quas. Provident ",
    },
  ];

  return (
    <div className="carousel-container">
      <Splide
        options={{
          type: "loop",
          perPage: 4,
          perMove: 1,
          gap: "1.5rem",
          pagination: true,
          arrows: false,
          autoplay: true,
          interval: 2000,
          speed: 30000,
          pauseOnHover: true,
          padding: "1rem",
          breakpoints: {
            640: {
              perPage: 1,
            },
            768: {
              perPage: 2,
            },
            984: {
              perPage: 3,
            },
          },
        }}
      >
        {testimonials.map((item, index) => (
          <SplideSlide key={index}>
            <div className="testimonial-card">
              <div className="testimonial-card-content">
                <img
                  src={item.imgSrc}
                  alt={`${item.name}'s testimonial`}
                  className="testimonial-image"
                />
                <h3 className="testimonial-name">{item.name}</h3>
              </div>
              <p className="testimonial-text">{item.testimonial}</p>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default CardCarousel;
