import React, { useState, useEffect, useCallback } from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import loginIcon from "../Home/images/login.svg";
import contactIcon from "../Home/images/contact.svg";
import Hamburger from "hamburger-react";
import LoginIcon from "../Home/utils/LoginIcon";
import ContactIcon from "../Home/utils/ContactIcon";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/auth/authSlice";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import MyAccordion from "../Miui/Accordion/MyAccordion";

const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [hamburgerSize, setHamburgerSize] = useState(35);

  const { isAuth } = useSelector((state) => state.auth);

  const token = Cookies.get("token");

  const dispatch = useDispatch();

  const handleResize = useCallback(() => {
    const width = window.innerWidth;

    setIsMobile(width <= 1150);

    if (width > 1150) {
      setOpen(false);
    }

    // Hamburger size logic
    if (width <= 490) {
      setHamburgerSize(20);
    } else if (width <= 768) {
      setHamburgerSize(25);
    } else {
      setHamburgerSize(35);
    }
  }, []);

  useEffect(() => {
    // Initial call
    handleResize();

    // Add resize listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const handleMenu = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleLogin = useCallback(() => {
    if (token) {
      Cookies.remove("token");
      dispatch(logout());
      toast.success("User logged out successfully");
    } else {
      navigate("/login");
    }
  }, [isAuth, navigate, dispatch]);

  return (
    <nav id="navbar">
      <div className="navbar-content">
        <div className="logo">
          <Link to="/homeabhi9331553831">
            <img src="/images/LOGO.svg" className="image-resize" alt="logo" />
          </Link>
        </div>

        {isMobile && (
          <div className="hamburger">
            <Hamburger
              toggled={isOpen}
              toggle={handleMenu}
              size={hamburgerSize}
            />
          </div>
        )}

        <div
          className={`menu-container ${isOpen ? "open" : ""} ${
            isMobile ? "mobile" : ""
          }`}
        >
          <ul className="menu">
            <li>
              <Link to="/strategy">Strategy</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/product">Product</Link>
            </li>
            {/* <li>
              <Link to="/about-us">About Us</Link>
            </li> */}
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
          </ul>

          {isMobile && (
            <div className="actions">
              <button
                type="button"
                aria-label="Login"
                className="login"
                onClick={handleLogin}
              >
                {token ? "Logout" : "Login"}
                <img src={loginIcon} alt="login icon" />
              </button>
              <button
                type="button"
                aria-label="Contact"
                className="talk-to-us"
                onClick={() => (window.location.href = "tel:+919902370041")}
              >
                Talk to us
                <img src={contactIcon} alt="contact icon" />
              </button>
            </div>
          )}
        </div>

        {!isMobile && (
          <div className="actions">
            <button
              type="button"
              aria-label="Login"
              className="login"
              onClick={handleLogin}
            >
              {token ? "Logout" : "Login"}
              <LoginIcon />
            </button>
            <button
              type="button"
              aria-label="Contact"
              className="talk-to-us"
              onClick={() => (window.location.href = "tel:+919902370041")}
            >
              Talk to us
              <ContactIcon />
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
