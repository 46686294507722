import React from "react";
import "./Construction.css";
import constructionImage from "./construction_image.svg";

const Construction = () => {
  return (
    <div>
      <div id="construction">
        <div className="construction-first-row">
          <div>
            <p>
              We're Building <span>Something Exciting !</span>
            </p>
            <p>
              This page is currently under construction as we work to bring you
              new and improved features.
            </p>
          </div>
          <div>
            <img src={constructionImage} alt="" />
          </div>
        </div>

        <div className="construction-second-row">
          <p>Thank you for visiting!</p>
          <p>We appreciate your patience. Stay tuned for the launch!</p>
        </div>
      </div>
    </div>
  );
};

export default Construction;
