import React, { useState } from "react";
import "./Admin-login.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Please fill Email and password");
      return;
    }

    if (
      email === "abhishekbothra@fractioned.in" &&
      password === "abhi9331553831@2023"
    ) {
      Cookies.set("isAuthenticated", "true", { expires: 1 / 24 });
      navigate("/admin-dashboard");
    } else {
      toast.error("Invalid Email or password");
    }
  };

  return (
    <div id="admin-login">
      <h1>Welcome To Admin Login</h1>

      <form type="submit" className="admin-validation-form">
        <input
          type="text"
          placeholder="Enter Email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          aria-label="Enter Email"
        />
        <input
          type="password"
          placeholder="Enter Password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          aria-label="Enter Password"
        />

        <div className="checkbox-container">
          <input
            type="checkbox"
            className="show-password"
            checked={showPassword}
            onChange={(e) => setShowPassword(e.target.checked)}
          />
          <p className="show-password">
            {showPassword ? password : "show password"}
          </p>
        </div>

        <button
          type="submit"
          className="form-submit-btn"
          onClick={handleSubmit}
        >
          SUBMIT
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
