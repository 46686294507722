import React from "react";
import NavbarDashboard from "./NavbarDashboard/NavbarDashboard";
import DashboarContent from "./DashboardContent.jsx/DashboarContent";

const Dashboard = () => {
  return (
    <>
      <NavbarDashboard />
      <DashboarContent />
    </>
  );
};

export default Dashboard;
