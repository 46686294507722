import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Flex,
  Grid,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import "./MyAccordion.css";

const data = [
  {
    question: "How is the safety of my investments and funds ensured ?",
    answer:
      "Your investment is secured by a first right on the receivables of the SMEs, as well as by other collateral (e.g. postdated cheque, visibility on the financial transactions, etc.). Different projects have different risk mitigation measures, which you can find in the information for each project. Fractioned does not manage or control the funds but do has the visibility on the fund movement.",
  },
  {
    question: "How much can I earn ?",
    answer:
      "The amount of the interest earned depends on the amount invested. As the platform works on the interest principle, the higher the investment and risk, the higher the earnings. The interest on projects varies from 16% to 22%.",
  },
  {
    question: "What taxes are applicable to individual investors ?",
    answer:
      "The interest earned is taxable as personal income and taxed accordingly. We will provide the necessary details of the interest you have received during the financial year.",
  },
  {
    question: "What if the project owner can't repay on time ?",
    answer:
      "The borrower is urged to fulfil his/her obligations in accordance with predefined rules. If the payment or payments are not made within the set period of time, a recovery process that is administered by Fractioned is initiated. You will be kept informed of the entire process via the contacts provided.",
  },
  {
    question: "What loans are funded on the Fractioned platform ?",
    answer:
      "Fractioned connects with retail investors for various types of business loans. Currently, Fractioned platform finances two main products. The first is purchase order financing, where the right over receivables from MNCs is created to secure investors' interests. The second, receivable discounting where receivables from MNCs against sale orders is charged to secure the investors' interests. In all the case, we also secure other collateral like postdated cheques, personal guarantee of the business promoter/founder and visibility of the bank account. ",
  },
  {
    question: "Can I lose my entire investment ?",
    answer:
      "As all projects are secured by assets, the total loss of the investment is unlikely. Be that as it may, investing always involves the risk of losing part or all of the investment.",
  },
  {
    question: "What happens if the project is only partially funded ?",
    answer:
      "In case the investment required for the project is not raised in the first instance, the project is dropped, and no money is drawn from investors.",
  },
  {
    question: "Can I retrieve my investment prematurely ?",
    answer:
      "Unfortunately, there is no early withdrawal option, but we will work with you to get an early withdrawal opportunity.",
  },
];

const MyAccordion = () => {
  return (
    <>
      <div id="sixth-section">
        <h1>
          Have questions?<span> We’ve got you.</span>
          <p>
            We’ve answered the most common questions to guide you through our
            services and investment options with ease.
          </p>
        </h1>

        <div className="faq-grid">
          <Grid id="main-accordion">
            {data.map((item, index) => (
              <Box key={index}>
                <Accordion allowToggle>
                  <AccordionItem
                    className="accordion-box"
                    border="0.5px solid"
                    borderColor="#E9E9E9"
                    borderRadius="lg"
                    p={5}
                    backgroundColor="#F6F6F7"
                  >
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton
                          _expanded={{ bg: "gray.100" }}
                          borderBottomRadius={isExpanded ? 0 : "md"}
                          className="accordion-button"
                        >
                          <Flex
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box flex="1" textAlign="left" className="question">
                              {item.question}
                            </Box>

                            {isExpanded ? (
                              <CloseIcon fontSize="10px" />
                            ) : (
                              <AddIcon fontSize="11px" />
                            )}
                          </Flex>
                        </AccordionButton>

                        <AccordionPanel pb={4} className="answer">
                          {item.answer}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </Accordion>
              </Box>
            ))}
          </Grid>
        </div>

        <div className="sixth-section-last">
          <p>Have a question in mind?</p>
          <p>
            Contact us, support center 24/7 <span>Schedule a Call</span>
          </p>
        </div>
      </div>

      {/* <Grid id="main-accordion">
        {data.map((item, index) => (
          <Box key={index}>
            <Accordion allowToggle>
              <AccordionItem
                className="accordion-box"
                border="0.5px solid"
                borderColor="#E9E9E9"
                borderRadius="lg"
                p={5}
                backgroundColor="#F6F6F7"
              >
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      _expanded={{ bg: "gray.100" }}
                      borderBottomRadius={isExpanded ? 0 : "md"}
                      className="accordion-button"
                    >
                      <Flex
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box flex="1" textAlign="left" className="question">
                          {item.question}
                        </Box>

                        {isExpanded ? (
                          <CloseIcon fontSize="10px" />
                        ) : (
                          <AddIcon fontSize="11px" />
                        )}
                      </Flex>
                    </AccordionButton>

                    <AccordionPanel pb={4} className="answer">
                      {item.answer}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </Box>
        ))}
      </Grid> */}
    </>
  );
};

export default MyAccordion;
