import React from "react";
import "./DashboardContent.css";
import quoteImage from "./images/dashboard-quote-1.svg";
import helpImage from "./images/dashboard-help.svg";
import { Navigate, useNavigate } from "react-router-dom";
import DashboardProducts from "../DashboardProducts/DashboardProducts";

const DashboarContent = () => {
  const navigate = useNavigate();

  const handleQuickChat = () => {
    window.location.href = "tel:+919902370041";
  };

  const handleBookCall = () => {
    navigate("/");
  };

  return (
    <>
      <div id="dashboard-content">
        <div className="dashboard-top">
          <div className="dashboard-top-left">
            <img src={quoteImage} alt="quote-img" />
          </div>

          <div className="dashboard-top-right">
            <img src={helpImage} alt="help-img" />
            <div className="help-btn-div">
              <button className="help-btn" onClick={handleQuickChat}>
                QUICK CHAT
              </button>
              <button className="help-btn" onClick={handleBookCall}>
                BOOK A CALL
              </button>
            </div>
          </div>
        </div>

        <div className="products">
          <h1>Choose what fits you best</h1>

          <DashboardProducts />
        </div>
      </div>

      <div className="dashboard-footer">
        <div className="dashboard-footer-content">
          <p>
            Disclaimer: &nbsp;
            <span>
              Fractioned is committed to maintaining a rigorous evaluation
              process, conducted by our proficient finance team, to vet the
              credentials of each business featured on our platform. As an
              organization that prioritizes retail investors, we enforce
              stringent compliance standards to ensure only credible and
              authentic listings are made available for investment.
              Nevertheless, while these investments are generally backed by
              tangible assets, they are accompanied by a moderate level of risk,
              including potential delays in repayment. It is crucial to
              acknowledge that, although the probability of losing your
              principal investment is minimal, it remains a possibility. © 2024
              Fractioned Technologies & Business Management Private Limited. All
              rights reserved. | CIN: UXXXXXXXXXXXX Fractioned is an
              independent, SEBI-registered platform specializing in asset-backed
              investments across the MSME sector. | SEBI Registration Number:
              INZ000XXXXXX Registered Office: 4th floor, Ankit Saphire, 100 Feet
              Rd, Indiranagar, Bengaluru, Karnataka 560008 Contact Us: For
              queries or support, reach out to our customer service team: Email:
              hello@fractioned.capital | Phone: +91-XXXXXXXXXX Investor Safety
              and Awareness: We ensure transparency and provide regular updates
              to all investors. | For additional support, use SEBI's grievance
              redressal system [SCORES](https://www.scores.gov.in). | Important:
              Never share account credentials with unauthorized individuals or
              entities. Quick Links: Terms & Conditions, Privacy Policy,
              Investor Charter, Risk Disclosure, Compliance Guidelines
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default DashboarContent;
