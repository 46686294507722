import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { SpinnerInfinity } from "spinners-react";
import Cookies from "js-cookie";
import { loginSuccess } from "../../features/auth/authSlice";
import axios from "axios";

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuth, isLoading, isToken } = useSelector((state) => state.auth);

  const hasNotified = useRef(false);

  const token = Cookies.get("token");
  console.log(token);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (token) {
          const verifyRes = await axios.get(
            "https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/prod/user/verify-token",
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          dispatch(loginSuccess({ user: verifyRes.data["user"] }));
        }
      } catch (error) {
        console.error("Token verification failed:", error);
        toast.error("Session expired, please login again!");
        Cookies.remove("token");
      }
    };

    verifyToken();
  }, [token, dispatch]);

  useEffect(() => {
    if (!isAuth && !hasNotified.current && !token) {
      toast.error("Please login to access the dashboard !", {
        autoClose: 3000,
      });
      hasNotified.current = true;
    }
  }, [isAuth]);

  if (isLoading) {
    return (
      <div>
        <SpinnerInfinity
          size={90}
          thickness={180}
          speed={105}
          color="rgba(172, 57, 59, 1)"
          secondaryColor="rgba(0, 0, 0, 0.78)"
        />
      </div>
    );
  }

  if (token) {
    return children;
  } else if (!isAuth || !token) {
    return <Navigate to="/login" replace />;
  }

  hasNotified.current = false;
  return children;
};

export default PrivateRoute;
