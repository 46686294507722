import React, { useEffect, useRef, useState } from "react";
import "./LoginOtp.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess } from "../../features/auth/authSlice";
import Cookies from "js-cookie";

const LoginOtp = ({ length = 4, value }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const number = value;

  const [otp, setOtp] = useState(new Array(length).fill(""));
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const inputsRef = useRef([]);

  const { isAuth, user, isLoading, isToken } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isAuth && isToken) {
      Cookies.set("token", isToken, {
        expires: 1 / 24,
        secure: true,
        sameSite: "Strict",
        path: "/",
      });
    }
  }, [isAuth, isToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const handleChange = (e, index) => {
    const { value } = e.target;

    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      // console.log(newOtp);
      // Move focus to the next input ---->
      if (index < length - 1) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();

      const newOtp = [...otp];

      if (newOtp[index] !== "") {
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        newOtp[index - 1] = "";
        setOtp(newOtp);
        inputsRef.current[index - 1].focus();
      }
    }

    if (e.key === "ArrowLeft" && index > 0) {
      inputsRef.current[index - 1].focus();
    }
    if (e.key === "ArrowRight" && index < length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData("text")
      .slice(0, length - index)
      .split("");

    if (pastedData.every((char) => /^\d$/.test(char))) {
      const newOtp = [...otp];
      pastedData.forEach((value, i) => {
        const currentIndex = index + i;
        if (currentIndex < length) {
          newOtp[currentIndex] = value;
        }
      });
      setOtp(newOtp);

      const nextEmptyIndex = newOtp.findIndex(
        (value, i) => i >= index && value === ""
      );
      if (nextEmptyIndex !== -1 && nextEmptyIndex < length) {
        inputsRef.current[nextEmptyIndex].focus();
      } else {
        inputsRef.current[length - 1].focus();
      }
    }
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/prod/user/resend-otp",
        { number: value }
      );
      if (res.status === 200) {
        toast.success("OTP resent successfully.");
        setMinutes(0);
        setSeconds(59);
      }
      // console.log(res.status);
    } catch (error) {
      const res_error = error.message;
      // console.log(res_error);
      if (res_error === "Request failed with status code 404") {
        toast.error("Oops! Time exceeded, please refresh");
      } else if (res_error === "Request failed with status code 500") {
        toast.error("Please wait for 1 minute to resend OTP");
      } else {
        toast.error(res_error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join("");

    if (otpValue.length === length) {
      try {
        const res = await axios.post(
          "https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/prod/user/verify",
          { number: number, otp: otpValue }
        );

        toast.success(res.data.message);
        const token = res.data.token;

        const verifyRes = await axios.get(
          "https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/prod/user/verify-token",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        dispatch(loginSuccess({ user: res.data }));

        navigate("/dashboard");
      } catch (error) {
        const res_error = error.response.data;
        console.log(error.response.data);
        if (res_error) {
          toast.error(res_error);
        }
      }
    } else {
      toast.error("Please fill out the OTP");
    }
  };

  return (
    <div className="auth-form">
      <h1>Welcome to Fractioned !</h1>

      <h1 className="second-heading">Secure Access, Made Simple.</h1>
      <form onSubmit={handleSubmit} className="login-form">
        <div style={{ display: "flex", justifyContent: "center" }}>
          {Array.from({ length }).map((_, index) => (
            <input
              key={index}
              type="text"
              inputMode="numeric"
              maxLength="1"
              value={otp[index]}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={(e) => handlePaste(e, index)}
              ref={(el) => (inputsRef.current[index] = el)}
              style={{
                width: "40px",
                height: "40px",
                margin: "0 5px",
                textAlign: "center",
                fontSize: "18px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ))}
        </div>

        <button type="submit" className="login-button">
          Verify OTP
        </button>

        <h1 className="below-heading">
          By continuing, you agree to our{" "}
          <span>
            <a
              href="https://drive.google.com/file/d/1p5FdcGKArqf9UrcniUfOIu_ycuzY3Mvi/view?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy.
            </a>
          </span>
        </h1>

        <div className="countdown-text">
          <div className="countdown-text-heading">
            <h1>
              Time Remaining:
              <span style={{ fontWeight: "600" }}>
                &nbsp;
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </span>
            </h1>
          </div>
          <button
            className="login-button"
            style={{
              backgroundColor: seconds > 0 || minutes > 0 ? "grey" : "green",
              color: "white",
            }}
            disabled={seconds > 0 || minutes > 0}
            onClick={resendOtp}
          >
            Resend OTP
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginOtp;
