import "../Home/Home.css";

import React, { useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  Text,
  StepDescription,
  StepSeparator,
  useSteps,
  ChakraProvider,
  extendTheme,
  Flex,
} from "@chakra-ui/react";
import gif1 from "../Home/images/1.jpg";
import gif2 from "../Home/images/2.jpg";
import gif3 from "../Home/images/3.jpg";
import gif4 from "../Home/images/4.jpg";

const theme = extendTheme({
  components: {
    Stepper: {
      baseStyle: {
        indicator: {
          borderColor: "grey",
        },
      },
    },
  },
  colors: {
    blue: {
      500: "grey",
    },
  },
});

const steps = [
  {
    title: "STEP 1",
    description: "Login to get Started",
    gif: gif1,
  },
  {
    title: "STEP 2",
    description: "Explore your Dashboard",
    gif: gif2,
  },
  {
    title: "STEP 3",
    description: "Pick the perfect Product",
    gif: gif3,
  },
  {
    title: "STEP 4",
    description: "Relax, We'll handle the Rest !",
    gif: gif4,
  },
];

function VerticalLinearStepper() {
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [selectedStep, setSelectedStep] = useState(0);

  const handleStepClick = (index) => {
    setActiveStep(index);
    setSelectedStep(index);
  };

  return (
    <>
      <div className="investment-steps">
        <h1 className="investment-steps-heading">
          Invest
          <span>
            &nbsp; in <br />4 Simple Steps
          </span>
        </h1>
        <ChakraProvider theme={theme}>
          <Box mt={5}>
            <Stepper
              index={activeStep}
              orientation="vertical"
              gap="0"
              height="auto"
              spacing="0"
            >
              {steps.map((step, index) => (
                <Step
                  key={index}
                  onClick={() => handleStepClick(index)}
                  cursor="pointer"
                >
                  <Flex alignItems="center" height="80px" position="relative">
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon color="white" />}
                        incomplete={<StepNumber color="#c0c0c0" />}
                        active={<StepNumber color="grey" />}
                      />
                    </StepIndicator>
                    <Box ml={4} width="180px" p={2}>
                      <StepDescription className="step-description">
                        {step.description}
                      </StepDescription>
                    </Box>

                    {index !== steps.length - 1 && (
                      <StepSeparator
                        position="absolute"
                        left="100px"
                        height="40px"
                        top="60px"
                        bg="#E9353F"
                        style={{
                          position: "absolute",
                          left: "15px",
                          top: "60px",
                          background: "grey",
                        }}
                      />
                    )}
                  </Flex>
                </Step>
              ))}
            </Stepper>
          </Box>
        </ChakraProvider>
      </div>
      <div className="investment-description">
        <img src={steps[selectedStep].gif} alt="description-image" />
      </div>
    </>
  );
}

export default VerticalLinearStepper;
