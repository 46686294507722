import React from "react";
import "./NavbarDashboard.css";
import logo from "./dash_logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import Cookies from "js-cookie";
import toast from "react-hot-toast";


const NavbarDashboard = () => {
  
  const { isAuth, isToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("token");
    dispatch(logout());
    toast.success("User logged out successfully");
    navigate("/homeabhi9331553831");
  };
  

  return (
    <div id="navbar-container">
      <div className="fractioned-logo">
        <Link to="/homeabhi9331553831">
          <img src={logo} alt="fractioned-logo" />
        </Link>
      </div>

      <div className="dashboard-products">
        <p className="products">Invoices</p>
        <p className="products">Purchases</p>
        <p className="products">Capital</p>
        <p className="products">Machinery</p>
      </div>

      <div className="login-logout-btn">
        <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
  
};

export default NavbarDashboard;
