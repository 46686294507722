import React, { useState } from "react";
import "./Seeproducts.css";
import axios from "axios";
import EditProduct from "../editProduct/EditProduct";
import toast from "react-hot-toast";

const SeeProducts = ({ products }) => {
  const data = products;

  const [formData, setFormData] = useState({
    productName: "",
    minimumInvestment: "",
    soldPercentage: "",
    tenure: "",
    XIRR: "",
    categoryName: "",
    categoryDescription: "",
  });

  const [editingProductId, setEditingProductId] = useState(null);

  const handleEdit = async (id) => {
    console.log(id);
    try {
      const response = await axios.get(
        `https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products`
      );
      const product = response.data;
      const data = product.data;
      console.log(data);

      const selectedProduct = data.find((el) => el._id === id);

      if (selectedProduct) {
        setFormData({
          productName: selectedProduct.productName || "",
          minimumInvestment: selectedProduct.minimumInvestment || "",
          soldPercentage: selectedProduct.soldPercentage || "",
          tenure: selectedProduct.tenure || "",
          XIRR: selectedProduct.XIRR || "",
          categoryName: selectedProduct.categoryName || "",
          categoryDescription: selectedProduct.categoryDescription || "",
        });
      } else {
        console.error("No product found with the given id");
      }
      setEditingProductId(id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    console.log(id);
    try {
      const response = await axios.delete(
        `https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products/deleteproduct/${id}`
      );

      console.log(response.data.message);
      toast.success(response.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {editingProductId && (
        // <form onSubmit={handleSubmit}>
        <div className="product-form">
          <h2>Edit Product</h2>
          <EditProduct
            formData={formData}
            editingProductId={editingProductId}
          />
        </div>
        // </form>
      )}

      <div className="product-list">
        <h2>Products</h2>
        {data.length > 0 ? (
          <ul>
            {data.map((product, index) => (
              <li key={index}>
                {/* {console.log(product._id)} */}
                <strong>{product.productName}</strong>
                <p>Minimum Investment: {product.minimumInvestment}</p>
                <p>Sold Percentage: {product.soldPercentage}</p>
                <p>Tenure: {product.tenure}</p>
                <p>XIRR: {product.XIRR}</p>
                <p>Category: {product.categoryName}</p>
                <p>Description: {product.categoryDescription}</p>
                <div className="edit-delete-button">
                  <button onClick={() => handleEdit(product._id)}>Edit</button>
                  <button onClick={() => handleDelete(product._id)}>
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>products Loading</p>
        )}
      </div>
    </div>
  );
};

export default SeeProducts;
