import "./App.css";
// import Home from "./Home/Home";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AllRoutes from "./Routes/AllRoutes";
import { ChakraProvider } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import store from "./Redux/Store/store";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ChakraProvider>
          <BrowserRouter>
            <Toaster />
            <AllRoutes />
          </BrowserRouter>
        </ChakraProvider>
      </Provider>
    </div>
  );
}

export default App;
