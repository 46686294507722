import React, { useState } from "react";
import toast from "react-hot-toast";

const EditProduct = ({ formData, editingProductId }) => {
  console.log(formData, editingProductId);

  const id = editingProductId;
  
  const [editFormData, setEditFormData] = useState({
    productName: formData.productName,
    minimumInvestment: formData.minimumInvestment,
    soldPercentage: formData.soldPercentage,
    tenure: formData.tenure,
    XIRR: formData.XIRR,
    categoryName: formData.categoryName,
    categoryDescription: formData.categoryDescription,
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(`Updating field: ${name}: Value: ${value}`);
    setEditFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    console.log("Form data", formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      const response = await fetch(
        `https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/dev/products/updateproduct/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editFormData),
        }
      );
      const result = await response.json();
      setLoading(false);
      toast.success("Product created successfully");

      if (response.ok) {
        setMessage("Product added successfully!");
        setEditFormData({
          productName: "",
          minimumInvestment: "",
          soldPercentage: "",
          tenure: "",
          XIRR: "",
          categoryName: "",
          categoryDescription: "",
        });
      } else {
        setMessage(result.message || "Failed to add product.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="product-form-div">
      <form id="create-product-form" onSubmit={handleSubmit}>
        <div>
          <label>Product Name:</label>

          <input
            className="input-field"
            type="text"
            name="productName"
            value={editFormData.productName}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Minimum Investment:</label>
          <input
            type="number"
            name="minimumInvestment"
            value={editFormData.minimumInvestment}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Sold Percentage:</label>
          <input
            type="number"
            name="soldPercentage"
            value={editFormData.soldPercentage}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Tenure:</label>
          <input
            type="number"
            name="tenure"
            value={editFormData.tenure}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>XIRR:</label>
          <input
            type="number"
            name="XIRR"
            value={editFormData.XIRR}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Category Name:</label>
          <input
            type="text"
            name="categoryName"
            value={editFormData.categoryName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Category Description:</label>
          <textarea
            type="text"
            name="categoryDescription"
            value={editFormData.categoryDescription}
            onChange={handleChange}
          ></textarea>
        </div>

        <button className="product-create-btn" type="submit" disabled={loading}>
          {loading ? "Submitting..." : "Create Product"}
        </button>
      </form>
    </div>
  );
};

export default EditProduct;
